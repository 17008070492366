.auth-form__input {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  border: none;
  margin: 0;
  padding: 0 0 216px;
}

@media screen and (max-width: 560px) {
  .auth-form__input {
    padding-bottom: 173px;
  }
}
