.header__burger {
    display: none;
    width: 24px;
    height: 24px;    
    flex-direction: column;
    justify-content: space-between;
    border: none;
    background-color: transparent;
    color: #fff;
    padding: 0;
    margin: auto 30px auto auto;    
}

.header__burger:hover {
    cursor: pointer;
}

@media screen and (max-width: 560px) {
    .header__burger {        
        display: flex;        
    }
  }