.profile__add {
  min-width: 150px;
  height: 50px;
  margin: 0 0 0 auto;
  padding: 0;
  border: solid 2px #fff;
  border-radius: 2px;
  align-self: center;
  background-image: url(../../../images/Addbutton.svg);
  background-repeat: no-repeat;
  background-size: 22px;
  background-position: center;
  background-color: transparent;
  transition: .3s opacity;
  cursor: pointer;
}

.profile__add:hover {
  opacity: .6;
}

@media screen and (max-width: 900px) {
  .profile__add {
    width: 120px;
    height: 40px;
  }
}

@media screen and (max-width: 740px) {
  .profile__add {
    margin-left: 0;
    margin-top: 20px;
  }
}

@media screen and (max-width: 560px) {
  .profile__add {
    margin-top: 0;
    width: 100%;
    height: 50px;
    background-size: 16px;
  }
}
