.header__wrap {
  display: flex;
  width: 100%;  
  justify-content: space-between;
}

@media screen and (max-width: 560px) {
  .header__wrap {
    width: 100%;
    justify-content: space-between;
    border-top: 1px solid rgba(84, 84, 84, 0.7);
    border-bottom: 1px solid rgba(84, 84, 84, 0.7);
  }
}
