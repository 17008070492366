.popup__close {
  margin: 0;
  padding: 0;
  border: 0;
  background-image: url(../../../images/close-icon.svg);
  background-color: transparent;
  position: absolute;
  top: -40px;
  right: -40px;
  width: 32px;
  height: 32px;
  transition: .3s opacity;
  cursor: pointer;
}

@media screen and (max-width: 560px) {
  .popup__close {
    background-size: contain;
    width: 20px;
    height: 20px;
    top: -36px;
    right: 0;
  }
}

.form__close:hover {
    opacity: .6;
}
