.profile__info {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px 18px;
  margin: 0 32px 0 0;
}

@media screen and (max-width: 560px) {
  .profile__info {
    column-gap: 12px;
    margin: 26px 0 36px 28px;
    justify-items: center;
  }
}
