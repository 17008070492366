.auth-form__item {
  box-sizing: border-box;
  border: 0;
  background: #000;
  color: #fff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  padding: 0 0 10px;
  margin-top: 30px;
  width: 100%;
  height: 27px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

@media screen and (max-width: 560px) {
  .form__item {
    padding-bottom: 10px;
  }
}
