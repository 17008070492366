.profile {
  width: 100%;
  display: flex;
  padding: 40px 0 51px;
}

@media screen and (max-width: 740px) {
  .profile {
    flex-direction: column;
    align-items: center;
    padding-top: 42px;
    padding-bottom: 36px;
  }
}
