.profile__image {
  object-fit: cover;
  min-width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-right: 30px;
  transition: .3s opacity;
  cursor: pointer;
}

@media screen and (max-width: 560px) {
  .profile__image {
    margin-right: 0;
  }
}

.profile__image:hover {
  opacity: .2;
}
