.auth__heading {
  font-size: 24px;
  font-weight: 900;
  line-height: 100%;
  color: #fff;
  margin: 62px auto 22px;
  padding: 0;
}

@media screen and (max-width: 560px) {
  .popup__heading {
    font-size: 18px;
    padding: 27px 22px 48px;
  }
}
