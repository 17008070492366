.header__link-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  background-color: #000;
  color: #fff;
  margin: 0;
  padding: 12px 0 0;
  border: none;
  text-decoration: none;
  
  cursor: pointer;
  transition: .3s opacity;
}

.header__link-text:hover {
  opacity: .6;
}

@media screen and (max-width: 560px) {
  .header__link-text {    
    padding: 0;
    margin: 0 30px;
  }
}

