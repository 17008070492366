.footer__copyright {
  font-size: 18px;
  font-weight: 400;
  line-height: 100%;
  margin: 0;
  color: #545454;
  margin-top: 67px;
}

@media screen and (max-width: 560px) {
  .footer__copyright {
    margin-top: 48px;
    margin-right: 19px;
    margin-left: 19px;
    font-size: 14px;
  }
}
