.header {
  width: 100%;
  min-height: 84px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(84, 84, 84, .7);  
}

@media screen and (max-width: 560px) {
  .header {    
    flex-direction: column-reverse;
  }
}
