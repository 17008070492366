.auth-form {
  display: flex;
  flex-direction: column;  
  color: #fff;
  width: 358px;
  padding: 0;
  margin: 0 auto;
}

@media screen and (max-width: 560px) {
  .form {
    padding: 0;
  }
}
