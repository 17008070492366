.auth {
  box-sizing: border-box;
  background-color: #000;
  font-family: "Inter", Arial, sans-serif;
  color: #fff;
  min-width: 300px;
  max-width: 1280px;  
  margin: 0 auto;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  display: flex;
  flex-direction: column;
}
