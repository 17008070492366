.header__logo {
  width: 142px;
  height: 33px;
  object-fit: cover;
}

@media screen and (max-width: 560px) {
  .header__logo {
    margin: 28px 0 32px 30px;
    width: 104px;
    height: 24.4px;
  }
}
