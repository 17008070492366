.form__item {
  box-sizing: border-box;
  border: 0;  
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 0 12px;
  margin-top: 2px;
  width: 100%;
  height: 27px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
}

@media screen and (max-width: 560px) {
  .form__item {
    padding-bottom: 10px;
  }
}
