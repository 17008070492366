.popup__container {
  width: 100%;
  max-width: 430px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
  position: relative;
}

@media screen and (max-width: 560px) {
  .popup__container {
    max-width: 282px;
  }
}
