.header__nav {
  display: flex;
}

@media screen and (max-width: 560px) {
  .header__nav {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 34px 0;
  }
}
