.profile__name {
  align-self: end;
  margin: 2px 0 0 0;
  padding-bottom: 4px;
  font-size: 42px;
  line-height: 94%;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 900px) {
  .profile__name {
    font-size: 34px;
  }
}

@media screen and (max-width: 740px) {
  .profile__name {
    font-size: 28px;
  }
}

@media screen and (max-width: 560px) {
  .profile__name {
    font-size: 27px;
    max-width: 100%;
  }
}
