.profile__edit {
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  margin: 0 0 8px 0;
  padding: 0;
  border: 1px solid #fff;
  background-image: url(../../../images/edit-button.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px;
  background-color: rgba(0, 0, 0, 0);
  align-self: end;
  transition: .3s opacity;
  cursor: pointer;
}

.profile__edit:hover {
  opacity: .6;
}

@media screen and (max-width: 560px) {
  .profile__edit {
    width: 18px;
    height: 18px;
    background-size: 7.5px;
  }
}
