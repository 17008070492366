.header__email {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  background-color: #000;
  color: #fff;
  margin: 0;
  padding: 12px 24px 0 0;
  border: none;
  text-decoration: none;
}

@media screen and (max-width: 560px) {
  .header__email {    
    margin: 0 auto 18px;
    padding-top: 18px;   
  }
}

