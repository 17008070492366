.cards__like {

  margin: 22px 0 0;
  padding: 0 0 0 10px;
  border: 0;
  min-width: 22px;
  height: 19px;
  background-image: url(../../../images/like.svg);
  background-color: transparent;
  transition: .3s opacity;
  cursor: pointer;
}

.cards__like:hover {
  opacity: .5;
}
